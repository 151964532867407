



























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import MCheckbox from '@/components/common/MCheckbox.vue'
import { UpdatePayee } from '@/store/payees/types'
import MButton from '@/components/common/MButton.vue'
import MModalCard from '@/components/common/MModalCard.vue'
import MTextField from '@/components/common/MTextField.vue'
import { ButtonType } from './common/types'

@Component({
  components: { MButton, MCheckbox, MModalCard, MTextField }
})
export default class UpdatePayeeForm extends Vue {
  @Prop() value!: UpdatePayee
  @Prop() onUpdatePayee!: Function
  @Prop() onCancel!: Function

  ButtonType = ButtonType

  get newValue(): UpdatePayee {
    return this.value
  }

  onUpdatePayeeClicked(): void {
    this.onUpdatePayee()
  }
}
