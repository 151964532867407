












































import Component, { mixins } from 'vue-class-component'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import Utils from '@/utils'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import PayeeMixin from '@/mixins/PayeeMixin.vue'
import _ from 'lodash'
import { Payee, UpdatePayee } from '@/store/payees/types'
import UpdatePayeeForm from '@/components/UpdatePayeeForm.vue'
import MButton from '@/components/common/MButton.vue'
import { ButtonType } from '@/components/common/types'

@Component({
  components: {
    DefaultLayout,
    UpdatePayeeForm,
    MButton
  }
})
export default class PayeesView extends mixins(GlobalMixin, PayeeMixin) {
  payeeBeingEdited: Payee | null = null

  ButtonType = ButtonType

  get groupedPayees(): [string, Payee[]][] {
    const groups = _.groupBy(this.allPayees, Utils.firstLetterGrouping)
    const pairs = _.toPairs(groups)
    const favorites = this.allPayees.filter(p => p.favorite)
    if (favorites.length > 0) {
      pairs.splice(0, 0, ['Favorites', favorites])
    }
    return pairs
  }

  async onFavoriteClicked(payee: Payee): Promise<void | Payee> {
    if (!payee.id || !payee.name) {
      return
    }
    const updatePayee = new UpdatePayee(payee.id, payee.name, !payee.favorite)
    return this.updatePayee(updatePayee)
  }

  onPayeeClicked(payee: Payee): void {
    this.payeeBeingEdited = _.cloneDeep(payee)
  }

  async onFinishPayeeEditing(): Promise<void | Payee> {
    if (
      this.payeeBeingEdited &&
      this.payeeBeingEdited.id &&
      this.payeeBeingEdited.name
    ) {
      const updatePayee = new UpdatePayee(
        this.payeeBeingEdited.id,
        this.payeeBeingEdited.name,
        this.payeeBeingEdited.favorite
      )
      this.payeeBeingEdited = null
      return this.updatePayee(updatePayee)
    }
  }

  onCancelAnyPayeeEditing(): void {
    this.payeeBeingEdited = null
  }

  get favoriteIcon(): Function {
    return (favorite: boolean) => (favorite ? 'star' : 'star-outline')
  }
}
