import axios from '@/backend/axios'
import { MonthlyBalances } from './types'

export default interface ReportsApi {
  fetchMonthlyBalances(
    budgetId: string,
    from?: string,
    until?: string
  ): Promise<MonthlyBalances>
}

class RealReportsApi implements ReportsApi {
  private httpClient: any

  public constructor(httpClient: any) {
    this.httpClient = httpClient
  }

  public async fetchMonthlyBalances(
    budgetId: string,
    from?: string,
    until?: string
  ): Promise<MonthlyBalances> {
    const searchParams = new URLSearchParams()
    if (from) {
      searchParams.append('from', from)
    }
    if (until) {
      searchParams.append('until', until)
    }
    const response = await this.httpClient.get(
      `${budgetId}/reports/monthly-balances?${searchParams.toString()}`
    )
    return response.data as MonthlyBalances
  }
}

export const createReportsApi = (httpClient: any): ReportsApi => {
  return new RealReportsApi(httpClient)
}

export const defaultReportsApi = (): ReportsApi => {
  return createReportsApi(axios)
}
