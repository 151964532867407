






























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import BalanceLabel from '@/components/BalanceLabel.vue'
import MButton from '@/components/common/MButton.vue'
import { ButtonType } from '../common/types'

@Component({
  components: {
    BalanceLabel,
    MButton
  }
})
export default class SwitchingAmountInput extends Vue {
  @Prop() value!: number
  @Prop() onDone!: Function

  ButtonType = ButtonType

  inflow: boolean = this.value > 0

  mounted(): void {
    this.inflow = this.value > 0
  }

  get newValue(): number {
    return this.value
  }

  set newValue(newValue: number) {
    if (newValue !== this.value) {
      this.$emit('input', newValue)
    }
  }

  digitClicked(digit: number): void {
    let toAdd = digit
    if (!this.inflow) {
      toAdd *= -1
    }
    if (this.newValue === 0) {
      this.newValue = toAdd
    } else {
      this.newValue = this.newValue * 10 + toAdd
    }
  }

  deleteClicked(): void {
    let result = Math.abs(this.newValue)
    result = Math.floor(result / 10)
    if (!this.inflow) {
      result *= -1
    }
    this.newValue = result
  }

  get computedInflow(): boolean {
    return this.inflow
  }

  set computedInflow(value: boolean) {
    if (this.inflow === value) {
      return
    }
    this.inflow = value
    if (this.newValue !== 0) {
      this.newValue = this.newValue * -1
    }
  }
}
