

































































































































































import _ from 'lodash'
import moment from 'moment'
import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import AccountMixin from '@/mixins/AccountMixin.vue'
import CategoryMixin from '@/mixins/CategoryMixin.vue'
import TransactionEditingMixin from '@/mixins/TransactionEditingMixin.vue'
import TransactionStorage, {
  defaultTransactionStorage
} from '@/TransactionStorage'
import {
  Transfer,
  Status,
  RepeatingInterval
} from '../store/transactions/types'
import { Category } from '@/store/categories/types'
import DatePicker from '@/components/DatePicker.vue'
import SwitchingAmountInput from '@/components/mobile/SwitchingAmountInput.vue'
import BalanceLabel from '@/components/BalanceLabel.vue'
import MButton from '@/components/common/MButton.vue'
import MTextField from '@/components/common/MTextField.vue'
import MobileEntitySelector from '@/components/mobile/MobileEntitySelector.vue'
import CategorySelector from '@/components/CategorySelector.vue'
import { ButtonType } from '@/components/common/types'

@Component({
  components: {
    DatePicker,
    SwitchingAmountInput,
    BalanceLabel,
    MobileEntitySelector,
    CategorySelector,
    MButton,
    MTextField
  }
})
export default class TransferDetails extends mixins(
  TransactionEditingMixin,
  AccountMixin,
  CategoryMixin
) {
  transactionStorage: TransactionStorage = defaultTransactionStorage
  amountBeingEdited: boolean = false
  selectCategory: boolean = false
  selectAccount: boolean = false
  selectTargetAccount: boolean = false

  ButtonType = ButtonType

  mounted(): void {
    if (this.currentTransactionId === 'new-transfer') {
      const initialAccountId = this.currentAccountId
        ? this.currentAccountId
        : this.allAccounts[0].id
      const firstOtherAccount = this.allAccounts.find(
        account => account.id !== initialAccountId
      )
      this.transferBeingEdited = new Transfer(
        undefined,
        moment().format('YYYY-MM-DD'),
        initialAccountId,
        firstOtherAccount ? firstOtherAccount.id : undefined,
        undefined,
        undefined,
        undefined,
        Status.CLEARED,
        RepeatingInterval.NOT_REPEATING
      )
      this.amountBeingEdited = true
    } else if (this.currentTransactionId) {
      this.transactionStorage
        .findOne(this.currentTransactionId)
        .then(response => {
          const transaction = response.data as any
          this.transferOriginal = new Transfer(
            transaction.id,
            transaction.date,
            transaction.accountId,
            transaction.targetAccountId,
            transaction.categoryId,
            transaction.amount,
            transaction.description,
            transaction.status,
            transaction.repeatingInterval
          )
          this.transferBeingEdited = _.cloneDeep(this.transferOriginal)
        })
        .catch(e => console.error('error during mount', e))
    }
  }

  async onSaveClicked(): Promise<void> {
    return this.saveEdit()
      .then(finished => {
        if (finished) {
          this.navigateToTransactions()
        }
      })
      .catch(e => console.error('Error on save', e))
  }

  onCancelClicked(): void {
    this.cancelEdit()
    this.navigateToTransactions()
  }

  onBackClicked(): void {
    this.amountBeingEdited = false
    this.selectCategory = false
    this.selectAccount = false
    this.selectTargetAccount = false
  }

  navigateToTransactions(): void {
    if (this.currentAccountId) {
      this.$router.push({
        name: 'account-transactions',
        params: { accountId: this.currentAccountId }
      })
    } else {
      this.$router.push({ name: 'transactions' })
    }
  }

  get currentAccountId(): string {
    return this.$route.params.accountId
  }

  get title(): string {
    if (this.transferOriginal) {
      return 'Edit Transfer'
    }
    return 'Add Transfer'
  }

  onAmountClicked(): void {
    this.amountBeingEdited = true
  }

  onAmountInputDone(): void {
    this.amountBeingEdited = false
  }

  get categoryLabel(): string {
    if (this.transferBeingEdited && this.transferBeingEdited.categoryId) {
      return this.categoryName(this.transferBeingEdited.categoryId)
    } else {
      return 'Select Category'
    }
  }

  onCategorySelection(newCategory: Category): void {
    this.selectCategory = false
  }

  @Watch('transferBeingEdited.accountId')
  onAccountSelection(newAccountId: string): void {
    this.selectAccount = false
    if (this.transferBeingEdited && this.transferBeingEdited.targetAccountId) {
      const account = this.accounts.account(newAccountId)
      const targetAccount = this.accounts.account(
        this.transferBeingEdited.targetAccountId
      )
      if (account && targetAccount) {
        if (account.offBudget === targetAccount.offBudget) {
          this.transferBeingEdited.categoryId = null
        }
      }
    }
  }

  @Watch('transferBeingEdited.targetAccountId')
  onTargetAccountSelection(newTargetAccountId: string): void {
    this.selectTargetAccount = false
    if (this.transferBeingEdited && this.transferBeingEdited.accountId) {
      const account = this.accounts.account(this.transferBeingEdited.accountId)
      const targetAccount = this.accounts.account(newTargetAccountId)
      if (account && targetAccount) {
        if (targetAccount.offBudget === account.offBudget) {
          this.transferBeingEdited.categoryId = null
        }
      }
    }
  }

  @Watch('transferBeingEdited.date')
  onDateChanged(newDate: string): void {
    if (!this.transferBeingEdited) {
      return
    }

    if (moment(newDate).isAfter(moment())) {
      this.transferBeingEdited.status = Status.SCHEDULED
    } else {
      this.transferBeingEdited.repeatingInterval =
        RepeatingInterval.NOT_REPEATING
      if (this.transferBeingEdited.status === Status.SCHEDULED) {
        this.transferBeingEdited.status = Status.CLEARED
      }
    }
  }

  get isFutureTransaction(): boolean {
    if (!this.transferBeingEdited || !this.transferBeingEdited.date) {
      return false
    }
    return moment(this.transferBeingEdited.date).isAfter(moment())
  }

  get accountLabel(): string {
    if (this.transferBeingEdited) {
      return this.accountName(this.transferBeingEdited.accountId)
    } else {
      return 'Select Account'
    }
  }

  get targetAccountLabel(): string {
    if (this.transferBeingEdited) {
      return this.accountName(this.transferBeingEdited.targetAccountId)
    } else {
      return 'Select Account'
    }
  }

  get isOnDetailsPage(): boolean {
    return (
      this.amountBeingEdited ||
      this.selectAccount ||
      this.selectCategory ||
      this.selectTargetAccount
    )
  }

  get transferRequiresCategory(): boolean {
    if (!this.transferBeingEdited) {
      return false
    }

    const sourceAccount = this.account(this.transferBeingEdited.accountId)
    const targetAccount = this.account(this.transferBeingEdited.targetAccountId)

    if (!sourceAccount || !targetAccount) {
      return false
    }

    return sourceAccount.offBudget !== targetAccount.offBudget
  }

  get statusIsCleared(): boolean {
    return (
      !!this.transferBeingEdited &&
      (this.transferBeingEdited.status === Status.CLEARED ||
        this.transferBeingEdited.status === Status.RECONCILED)
    )
  }

  get isReconciled(): boolean {
    return (
      !!this.transferBeingEdited &&
      this.transferBeingEdited.status === Status.RECONCILED
    )
  }

  get repeatingIntervalKeys(): string[] {
    return Object.keys(RepeatingInterval)
  }

  onStatusChanged(newValue: boolean): void {
    if (!this.transferBeingEdited) {
      return
    }
    if (newValue) {
      this.transferBeingEdited.status = Status.CLEARED
    } else {
      this.transferBeingEdited.status = Status.UNCLEARED
    }
  }
}
