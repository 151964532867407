


































































































































































































import _ from 'lodash'
import moment from 'moment'
import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import AccountMixin from '@/mixins/AccountMixin.vue'
import CategoryMixin from '@/mixins/CategoryMixin.vue'
import PayeeMixin from '@/mixins/PayeeMixin.vue'
import TransactionEditingMixin from '@/mixins/TransactionEditingMixin.vue'
import TransactionStorage, {
  defaultTransactionStorage
} from '../TransactionStorage'
import {
  CategoryAmount,
  Payment,
  Status,
  RepeatingInterval
} from '@/store/transactions/types'
import { Account } from '@/store/accounts/types'
import { Category } from '@/store/categories/types'
import { Payee } from '@/store/payees/types'
import DatePicker from '@/components/DatePicker.vue'
import SwitchingAmountInput from '@/components/mobile/SwitchingAmountInput.vue'
import BalanceLabel from '@/components/BalanceLabel.vue'
import MobileEntitySelector from '@/components/mobile/MobileEntitySelector.vue'
import CategorySelector from '@/components/CategorySelector.vue'
import MButton from '@/components/common/MButton.vue'
import MIcon from '@/components/common/MIcon.vue'
import MTextField from '@/components/common/MTextField.vue'
import { ButtonType } from '@/components/common/types'

@Component({
  components: {
    DatePicker,
    SwitchingAmountInput,
    BalanceLabel,
    MobileEntitySelector,
    CategorySelector,
    MButton,
    MIcon,
    MTextField
  }
})
export default class PaymentDetails extends mixins(
  TransactionEditingMixin,
  AccountMixin,
  CategoryMixin,
  PayeeMixin
) {
  transactionStorage: TransactionStorage = defaultTransactionStorage
  amountBeingEdited: number = -1
  selectCategory: number = -1
  selectAccount: boolean = false
  selectPayee: boolean = false

  ButtonType = ButtonType

  mounted(): void {
    if (this.currentTransactionId === 'new-payment') {
      const initialAccountId = this.currentAccountId
        ? this.currentAccountId
        : undefined
      this.paymentBeingEdited = new Payment(
        undefined,
        moment().format('YYYY-MM-DD'),
        initialAccountId,
        undefined,
        undefined,
        undefined,
        Status.CLEARED,
        RepeatingInterval.NOT_REPEATING
      )
      this.amountBeingEdited = 0
    } else if (this.currentTransactionId) {
      this.transactionStorage
        .findOne(this.currentTransactionId)
        .then(response => {
          const transaction = response.data as any
          this.paymentOriginal = new Payment(
            transaction.id,
            transaction.date,
            transaction.accountId,
            transaction.payeeId,
            transaction.categoryAmounts,
            transaction.description,
            transaction.status,
            transaction.repeatingInterval
          )
          this.paymentBeingEdited = _.cloneDeep(this.paymentOriginal)
        })
        .catch(e => console.error('error during mount', e))
    }
  }

  async onSaveClicked(): Promise<void> {
    return this.saveEdit().then(finished => {
      if (finished) {
        this.navigateToTransactions()
      }
    })
  }

  async onCancelClicked(): Promise<void> {
    this.cancelEdit()
    this.navigateToTransactions()
  }

  onBackClicked(): void {
    this.amountBeingEdited = -1
    this.selectCategory = -1
    this.selectAccount = false
    this.selectPayee = false
  }

  navigateToTransactions(): void {
    if (this.currentAccountId) {
      this.$router.push({
        name: 'account-transactions',
        params: { accountId: this.currentAccountId }
      })
    } else {
      this.$router.push({ name: 'transactions' })
    }
  }

  get currentAccountId(): string {
    return this.$route.params.accountId
  }

  get title(): string {
    if (this.paymentOriginal) {
      return 'Edit Transaction'
    }
    return 'Add Transaction'
  }

  onAmountInputDone(): void {
    this.amountBeingEdited = -1
    if (this.paymentBeingEdited && !this.paymentBeingEdited.payeeId) {
      this.selectPayee = true
    }
  }

  onAddSubtransactionClicked(): void {
    if (!this.paymentBeingEdited) {
      return
    }
    if (this.paymentBeingEdited.categoryAmounts) {
      const categoryAmount = new CategoryAmount()
      categoryAmount.amount = 0
      this.paymentBeingEdited.categoryAmounts.push(categoryAmount)
      this.selectCategory = this.paymentBeingEdited.categoryAmounts.length - 1
    }
  }

  onDeleteSubtransactionClicked(index: number): void {
    if (!this.paymentBeingEdited) {
      return
    }
    if (
      this.paymentBeingEdited.categoryAmounts &&
      this.paymentBeingEdited.categoryAmounts.length > index
    ) {
      this.paymentBeingEdited.categoryAmounts.splice(index, 1)
    }
  }

  onAmountClicked(): void {
    if (!this.paymentBeingEdited) {
      return
    }
    if (
      this.paymentBeingEdited.categoryAmounts &&
      this.paymentBeingEdited.categoryAmounts.length === 1
    ) {
      this.amountBeingEdited = 0
    }
  }

  onCategoryAmountClicked(index: number): void {
    this.amountBeingEdited = index
  }

  get categoryLabel(): Function {
    return (categoryAmount: CategoryAmount) => {
      if (categoryAmount.categoryId) {
        return this.categoryName(categoryAmount.categoryId)
      } else {
        return 'Select Category'
      }
    }
  }

  onCategorySelection(newCategory: Category): void {
    this.selectCategory = -1
  }

  @Watch('paymentBeingEdited.accountId')
  onAccountSelection(newAccount: Account): void {
    this.selectAccount = false
  }

  @Watch('paymentBeingEdited.payeeId')
  onPayeeSelection(newPayee: Payee): void {
    this.selectPayee = false
    if (
      newPayee &&
      this.paymentBeingEdited &&
      !this.paymentBeingEdited.accountId
    ) {
      this.selectAccount = true
    }
  }

  @Watch('paymentBeingEdited.date')
  onDateChanged(newDate: string): void {
    if (!this.paymentBeingEdited) {
      return
    }

    if (moment(newDate).isAfter(moment())) {
      this.paymentBeingEdited.status = Status.SCHEDULED
    } else {
      this.paymentBeingEdited.repeatingInterval =
        RepeatingInterval.NOT_REPEATING
      if (this.paymentBeingEdited.status === Status.SCHEDULED) {
        this.paymentBeingEdited.status = Status.CLEARED
      }
    }
  }

  @Watch('paymentBeingEdited.payeeId')
  onPayeeIdChange(newPayeeId: string): void {
    if (!newPayeeId) {
      return
    }
    if (!this.paymentBeingEdited) {
      return
    }

    const categoryAmounts = this.paymentBeingEdited.categoryAmounts
    if (!categoryAmounts) {
      return
    }

    if (categoryAmounts.length !== 1) {
      return
    }

    if (categoryAmounts[0].categoryId) {
      return
    }

    const payee = this.payees.payee(newPayeeId)
    if (!payee) {
      return
    }

    const lastUsedCategoryIds = payee.lastUsedCategoryIds || []

    for (const index in lastUsedCategoryIds) {
      if (this.paymentBeingEdited.categoryAmounts[index]) {
        this.paymentBeingEdited.categoryAmounts[index].categoryId =
          lastUsedCategoryIds[index]
      } else {
        this.paymentBeingEdited.categoryAmounts.push(
          new CategoryAmount(lastUsedCategoryIds[index])
        )
      }
    }
  }

  get isFutureTransaction(): boolean {
    if (!this.paymentBeingEdited || !this.paymentBeingEdited.date) {
      return false
    }
    return moment(this.paymentBeingEdited.date).isAfter(moment())
  }

  get accountLabel(): string {
    if (this.paymentBeingEdited) {
      return this.accountName(this.paymentBeingEdited.accountId)
    } else {
      return 'Select Account'
    }
  }

  get payeeLabel(): string {
    if (this.paymentBeingEdited instanceof Payment) {
      if (this.paymentBeingEdited && this.paymentBeingEdited.payeeId) {
        return this.payeeName(this.paymentBeingEdited.payeeId)
      }
    }
    return 'Select Payee'
  }

  get isOnDetailsPage(): boolean {
    return (
      this.amountBeingEdited > -1 ||
      this.selectAccount ||
      this.selectCategory > -1 ||
      this.selectPayee
    )
  }

  get statusIsCleared(): boolean {
    return (
      !!this.paymentBeingEdited &&
      (this.paymentBeingEdited.status === Status.CLEARED ||
        this.paymentBeingEdited.status === Status.RECONCILED)
    )
  }

  get isReconciled(): boolean {
    return (
      !!this.paymentBeingEdited &&
      this.paymentBeingEdited.status === Status.RECONCILED
    )
  }

  get repeatingIntervalKeys(): string[] {
    return Object.keys(RepeatingInterval)
  }

  onStatusChanged(newValue: boolean): void {
    if (!this.paymentBeingEdited) {
      return
    }
    if (newValue) {
      this.paymentBeingEdited.status = Status.CLEARED
    } else {
      this.paymentBeingEdited.status = Status.UNCLEARED
    }
  }

  groupByAccountType(account: Account): string {
    return account.offBudget ? 'Off-Budget' : 'On-Budget'
  }

  favoriteFunction(payee: Payee): boolean {
    return !!payee.favorite
  }
}
