















import Chart, { ChartOptions } from 'chart.js'
import Component, { mixins } from 'vue-class-component'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { defaultReportsApi } from './ReportsApi'
import { MonthlyBalances } from './types'
import Utils from '@/utils'
import DateFilter from '@/components/DateFilter.vue'
import { DateRange } from '@/types'
import GlobalMixin from '../../mixins/GlobalMixin.vue'
import moment from 'moment'
import { Watch } from 'vue-property-decorator'
import { EventBus } from '@/EventBus'

@Component({
  components: {
    DefaultLayout,
    DateFilter
  }
})
export default class ReportsView extends mixins(GlobalMixin) {
  dateRange: DateRange = new DateRange()

  chart: Chart | null = null

  mounted(): void {
    EventBus.$on('toggle-theme', this.updateColors)
    defaultReportsApi()
      .fetchMonthlyBalances(this.currentBudgetId, this.from, this.until)
      .then(mb => {
        this.chart = this.createChart(
          'balance-report',
          this.createChartData(mb)
        )
      })
  }

  createChartData(monthlyBalances: MonthlyBalances): any {
    return {
      type: 'line',
      data: {
        labels: monthlyBalances.months,
        datasets: [
          {
            label: 'Balance',
            data: monthlyBalances.balances,
            backgroundColor: this.cssvar('--color-accent', 0.75),
            borderColor: this.cssvar('--color-accent'),
            borderWidth: 3,
            lineTension: 0
          }
        ]
      },
      options: this.createChartOptions()
    }
  }

  createChartOptions(): ChartOptions {
    return {
      responsive: true,
      tooltips: {
        callbacks: {
          label: function(tooltipItem: any, data: any): string {
            return Utils.toCurrency(tooltipItem.yLabel)
          }
        }
      },
      legend: {
        labels: {
          fontColor: this.cssvar('--color-font-secondary')
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              fontColor: this.cssvar('--color-font-secondary'),
              padding: 25,
              callback: function(
                value: number,
                index: number,
                values: string[]
              ): string {
                return Utils.toCurrency(value)
              }
            },
            gridLines: {
              color: this.cssvar('--color-background-contrast')
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              fontColor: this.cssvar('--color-font-secondary')
            },
            gridLines: {
              color: this.cssvar('--color-background-contrast')
            }
          }
        ]
      }
    }
  }

  createChart(chartId: string, chartData: any): any {
    const ctx = this.$refs.balanceReport as HTMLCanvasElement
    if (!ctx) {
      return
    }
    return new Chart(ctx, {
      type: chartData.type,
      data: chartData.data,
      options: chartData.options
    })
  }

  cssvar(name: string, alpha?: number): string {
    const value = getComputedStyle(document.documentElement).getPropertyValue(
      name
    )
    if (!alpha) {
      return value
    }
    return value.replace('hsl(', 'hsla(').replace(')', ', ' + alpha + ')')
  }

  updateChart(): void {
    defaultReportsApi()
      .fetchMonthlyBalances(this.currentBudgetId, this.from, this.until)
      .then(mb => {
        this.chart = this.createChart(
          'balance-report',
          this.createChartData(mb)
        )
      })
  }

  updateColors(): void {
    if (!this.chart) {
      return
    }
    const accentColor = this.cssvar('--color-accent')
    const backgroundColor = this.cssvar('--color-accent', 0.75)
    const dataset = this.chart.data.datasets?.[0]
    if (dataset) {
      dataset.backgroundColor = backgroundColor
      dataset.borderColor = accentColor
    }
    this.chart.options = this.createChartOptions()
    this.chart.update()
  }

  @Watch('dateRange')
  onDateFilterChanged(): void {
    this.updateUrl(this.dateRange)
  }

  @Watch('$route')
  onRouteChange(): void {
    let from: Date | null = null
    let until: Date | null = null
    const query = this.$route.query
    if (query && query.from) {
      from = moment(query.from as string).toDate()
    }
    if (query && query.until) {
      until = moment(query.until as string).toDate()
    }
    this.dateRange.from = from
    this.dateRange.until = until
    this.updateChart()
  }

  updateUrl(newDateRange: DateRange): void {
    this.$router.push({
      query: Object.assign({}, this.$route.query, {
        from: this.from,
        until: this.until
      })
    })
  }

  get from(): string | undefined {
    if (this.dateRange.from) {
      return moment(this.dateRange.from).format('YYYY-MM')
    }
  }

  get until(): string | undefined {
    if (this.dateRange.until) {
      return moment(this.dateRange.until).format('YYYY-MM')
    }
  }
}
